import React, { useState, useEffect } from 'react';
import CountText from '../../../../components/CountText';
import { useSplitData } from '../../../../context/useSplitData/useSplitData';
import { getSplitTotalsReport } from '../../../../services/split/requests';
import {
  CardContent,
  CardHeader, Wrapper, FullValue, FullValueContainer, FullValueText,
  NetValue,
  NetValueContainer,
  NetValueText,
  ShowReaulstsSpan,
  ShowResults,
  ShowResultsContainer,
  ShowResultsText,
  ToDoIcon, TransactionsAmountContainer,
  TransactionsReport, TransactionsReportContainer, Warning,
  WarningContainer, WarningText,
} from './styles';
import { Totals } from './types';

const CardTransactionReport: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totals, setTotals] = useState<Totals>({
    quantity: 0.0,
    total: 0.0,
    totalPaid: 0.0,
  });
  const { dateSelected } = useSplitData();

  const getReport = async () => {
    if (dateSelected.from && dateSelected.to) {
      try {
        const data = await getSplitTotalsReport({
          startDate: dateSelected.from.toISOString(),
          endDate: dateSelected.to.toISOString(),
        });
        setTotals({
          quantity: data.data.quantity,
          total: data.data.total / 100,
          totalPaid: data.data.totalPaid / 100,
        });
      } catch (error) {
        setTotals({
          quantity: 0.0,
          total: 0.0,
          totalPaid: 0.0,
        });
      }
    }
  };

  useEffect(() => {
    getReport();
  }, [dateSelected]);

  return (
    <Wrapper>
      <CardHeader>
        <TransactionsReportContainer>
          <ToDoIcon />
          <TransactionsReport>Relatório de transações</TransactionsReport>
        </TransactionsReportContainer>
        <WarningContainer>
          <WarningText>
            As transações podem levar de 2 a 12 horas para serem
            consolidadas na base de dados
          </WarningText>
          <Warning />
        </WarningContainer>
      </CardHeader>
      <CardContent>
        <FullValueContainer>
          <FullValueText>QUANTIDADE DE TRANSAÇÕES</FullValueText>
          <FullValue>
            {totals.quantity}
          </FullValue>
        </FullValueContainer>
        <NetValueContainer>
          <NetValueText>Total</NetValueText>
          <NetValue>
            <CountText value={totals.total} />
          </NetValue>
        </NetValueContainer>
        <NetValueContainer>
          <NetValueText>Total pago</NetValueText>
          <NetValue>
            <CountText value={totals.totalPaid} />
          </NetValue>
        </NetValueContainer>
        <TransactionsAmountContainer>
          <ShowResultsContainer>
            <ShowResultsText>Exibindo resultados de:</ShowResultsText>
            <ShowResults>
              <ShowReaulstsSpan style={{ fontWeight: 'bold' }}>
                {dateSelected.from?.toLocaleDateString()}
              </ShowReaulstsSpan>
              <ShowReaulstsSpan>até</ShowReaulstsSpan>
              <ShowReaulstsSpan style={{ fontWeight: 'bold' }}>
                {dateSelected.to?.toLocaleDateString()}
              </ShowReaulstsSpan>
            </ShowResults>
          </ShowResultsContainer>
        </TransactionsAmountContainer>
      </CardContent>
    </Wrapper>
  );
};

export default CardTransactionReport;
