import React, {
  lazy, useState, useRef,
} from 'react';
import {
  Container, TableContainer, PaginationContainer, PerPageContainer,
  PaginationButtonsContainer, ResultPerPage, Select, Option,
  OptionText, SelectDropDown, SelectDropDownUl, SelectDropDownLi,
  SelectDropDownLiP, TotalPagesContainer, ShowPage, CurrentPage,
  CurrentPageSpan, TabsContainer, Next, NextButton,
  Previous, PreviousButton, Page,
} from './styles';
// import { Transaction } from './types';
import CardTransactionsReport from './components/CardTransactionReport';
import AceptModal from './components/AceptModal';
import { useSplitData } from '../../context/useSplitData/useSplitData';

const TransactionsTable = lazy(() => import('./components/TransactionsTable'));
const SplitTab = lazy(() => import('./components/SplitTab'));
const Main = lazy(() => import('../../components/Main'));
const Toolbar = lazy(() => import('../../components/Toolbar'));

const PageContent: React.FC = () => {
  const {
    activePeriod, setActivePeriod, dateSelected,
    setDateSelected, setPaginationInfo, paginationInfo,
    isTableLoading, activeTab, setActiveTab,
  } = useSplitData();
  const [isPerPageOpen, setIsPerPageOpen] = useState<boolean>(false);
  const perPageRef = useRef<HTMLDivElement>(null);
  const handleChangePage = (action: number) => {
    if (
      paginationInfo?.currentPage + action <= paginationInfo.totalPages
      && paginationInfo?.currentPage + action !== 0
    ) {
      setPaginationInfo({
        ...paginationInfo,
        currentPage: paginationInfo?.currentPage + action,
      });
    }
  };

  return (
    <Main title="Split">
      <AceptModal />
      <Container>
        <Toolbar
          activePeriod={activePeriod}
          setActivePeriod={setActivePeriod}
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          showPeriodSelectors
          isTableLoading={isTableLoading}
        />
        <CardTransactionsReport />
        <TableContainer>
          <TabsContainer>
            <SplitTab
              active={activeTab === 'transactions'}
              clickHandler={() => setActiveTab('transactions')}
              title="Transações"
              reverse={false}
            />
          </TabsContainer>
          <TransactionsTable
            active={activeTab === 'transactions'}
          />
        </TableContainer>
        <PaginationContainer>
          <PerPageContainer>
            <ResultPerPage>Resultados por página</ResultPerPage>
            <Select
              ref={perPageRef}
              onClick={() => setIsPerPageOpen(!isPerPageOpen)}
            >
              <Option>
                <OptionText>{paginationInfo.limit}</OptionText>
              </Option>
              <SelectDropDown itIsOpen={isPerPageOpen}>
                <SelectDropDownUl>
                  <SelectDropDownLi onClick={
                      () => setPaginationInfo({ ...paginationInfo, limit: 10 })
                    }
                  >
                    <SelectDropDownLiP>10</SelectDropDownLiP>
                  </SelectDropDownLi>
                  <SelectDropDownLi onClick={
                    () => setPaginationInfo({ ...paginationInfo, limit: 50 })
                    }
                  >
                    <SelectDropDownLiP>50</SelectDropDownLiP>
                  </SelectDropDownLi>
                  <SelectDropDownLi onClick={
                    () => setPaginationInfo({ ...paginationInfo, limit: 100 })
                    }
                  >
                    <SelectDropDownLiP>100</SelectDropDownLiP>
                  </SelectDropDownLi>
                </SelectDropDownUl>
              </SelectDropDown>
            </Select>
            <TotalPagesContainer>
              <ShowPage>Exibindo página</ShowPage>
              <CurrentPage>
                {paginationInfo?.currentPage || 1}
                <CurrentPageSpan>de</CurrentPageSpan>
                {paginationInfo?.totalPages || 1}
                <CurrentPageSpan>
                  {paginationInfo?.totalPages > 1 ? 'páginas' : 'página'}
                </CurrentPageSpan>
              </CurrentPage>
            </TotalPagesContainer>
          </PerPageContainer>
          <PaginationButtonsContainer>
            <PreviousButton
              title="Página anterior"
              onClick={() => handleChangePage(-1)}
              style={paginationInfo?.currentPage === 1 ? { opacity: '.3' } : { opacity: 1 }}
              disabled={paginationInfo?.currentPage === 1}
            >
              <Previous />
            </PreviousButton>
            <Page>{paginationInfo?.currentPage}</Page>
            <NextButton
              title="Próxima página"
              onClick={() => handleChangePage(+1)}
              style={
                paginationInfo?.currentPage === paginationInfo?.totalPages
                  ? { opacity: '.3' }
                  : { opacity: 1 }
              }
              disabled={paginationInfo?.currentPage === paginationInfo?.totalPages}
            >
              <Next />
            </NextButton>
          </PaginationButtonsContainer>
        </PaginationContainer>
      </Container>
    </Main>
  );
};

export default PageContent;
