import React from 'react';
import PageContentSplit from './PageContent';
import { SplitDataProvider } from '../../context/useSplitData';

const Split = () => (
  <SplitDataProvider>
    <PageContentSplit />
  </SplitDataProvider>
);

export default Split;
