import React from 'react';
import CountUp from 'react-countup';

interface Props {
  value: string | number;
  notCurrency?: boolean
}

const CountText: React.FC<Props> = ({ value, notCurrency }) => (notCurrency ? (
  <CountUp
    end={Number(value)}
    separator="."
    duration={1}
  />
) : (
  <CountUp
    end={Number(value)}
    decimals={2}
    prefix="R$ "
    separator="."
    duration={1}
    decimal=","
  />
));

export default CountText;
