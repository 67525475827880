import axios from 'axios';
import { getToken } from './user';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASEURL}/api/clientreports`,
});

api.interceptors.request.use((config) => {
  const newConfig = config;
  const token = getToken();
  if (token) {
    newConfig.headers.Authorization = token;
  }
  return newConfig;
});

export default api;
