import axios from 'axios';
import { getToken } from './user';

const apiLogin = axios.create({
  baseURL: `${process.env.REACT_APP_LOGIN}`,
});

apiLogin.interceptors.request.use((config) => {
  const newConfig = config;
  const token = getToken();
  if (token) {
    newConfig.headers.Authorization = token;
  }
  return newConfig;
});

export default apiLogin;
