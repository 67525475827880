import React, { createContext, useEffect, useState } from 'react';
import { SplitPagination } from '../../services/split/type';
import { SplitDataContextData, SplitDataProviderProps } from './types';
import { Modifier } from '../../pages/Split/types';
import { Terminals } from '../../components/FilterForm/types';

export const SplitDataContext = createContext<SplitDataContextData>(
  {} as SplitDataContextData,
);

export function SplitDataProvider({ children }: SplitDataProviderProps) {
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('transactions');

  const [transactionCode, setTransactionCode] = useState<string>('');
  const [terminalsSelected, setTerminalsSelected] = useState<Terminals[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [flexRate, setFlexRate] = useState<number>();
  const [registersPerPage, setRegistersPerPage] = useState<number>(10);
  const [nsuCode, setNsuCode] = useState<string>('');
  const [receiptDate, setReceiptDate] = useState<string>('');
  const [payConditionSelected, setPayConditionSelected] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [activePeriod, setActivePeriod] = useState<number>(0);
  const [installmentSelected, setInstallmentSelected] = useState<number | null>(
    null,
  );

  const [dateSelected, setDateSelected] = useState<Modifier>({
    from: undefined,
    to: undefined,
  });

  const [allowChangePagination, setAllowChangePagination] = useState<boolean>(true);
  const [paginationInfo, setPaginationInfo] = useState<SplitPagination>({
    count: 1,
    currentPage: 1,
    limit: 10,
    totalCount: 1,
    totalPages: 1,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  return (
    <SplitDataContext.Provider
      value={{
        allowChangePagination,
        setAllowChangePagination,
        transactionCode,
        setTransactionCode,
        isTableLoading,
        setIsTableLoading,
        terminalsSelected,
        setTerminalsSelected,
        currentPage,
        setCurrentPage,
        flexRate,
        setFlexRate,
        registersPerPage,
        setRegistersPerPage,
        activeTab,
        setActiveTab,
        nsuCode,
        setNsuCode,
        receiptDate,
        setReceiptDate,
        payConditionSelected,
        setPayConditionSelected,
        installmentSelected,
        setInstallmentSelected,
        dateSelected,
        setDateSelected,
        paginationInfo,
        setPaginationInfo,
        showDeleteModal,
        setShowDeleteModal,
        setActivePeriod,
        activePeriod,
      }}
    >
      {children}
    </SplitDataContext.Provider>
  );
}
