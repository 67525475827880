import styled from 'styled-components';

export const Container = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s ease-in-out;
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100vh)')};
  @media (max-width: 460px) {
    padding: 0 20px;
  }
  background-color: rgb(0 0 0 / 30%);
`;

export const Modal = styled.div<{ show: boolean }>`
  position: relative;
  max-width: 600px;
  min-width: 300px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  padding: 40px;
`;

export const Title = styled.h2`
  margin: 20px 0;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.lightGray};
  max-width: 400px;
  text-align: left;
  line-height: 120%;
  margin-bottom: 16px;
  
  b{
    font-weight: bold;
  }
  span{
    color: #256DB1;
  }
  @media (max-width: 460px) {
    max-width: 350px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between
`;

export const SaveInfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const SaveCheck = styled.input.attrs({
  type: 'checkbox',
})`
  margin: auto;
  height: 13.8px;
  width: 13.8px;
`;
export const SaveText = styled.div`
  width: 214px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 100%;
  color: #292929;
  @media (max-width: 985px) {
    width: 100%;
  }
  b{
    font-weight: bold;
  }
`;

export const Link = styled.a``;
