import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import BackdropLoading from '../components/BackdropLoading';
import Main from '../components/Main';
import Route from './Route';

const EcSelection = lazy(() => import('../pages/Login'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Split = lazy(() => import('../pages/Split'));
const Help = lazy(() => import('../pages/Help'));
const ResetPassword = lazy(() => import('../pages/PasswordReset'));
const RedefinitionPassword = lazy(() => import('../pages/PasswordRedefinition'));

const PrivateRoutes: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<Main><BackdropLoading open /></Main>}>
      <Switch>
        <Route exact path="/" component={EcSelection} title="Seleção EC" />
        <Route exact path="/split" component={Split} title="split" />
        <Route exact path="/help" component={Help} title="Ajuda" />
        <Route exact path="/reset-password" component={ResetPassword} title="Reset Password" />
        <Route exact path="/password-redefinition" component={RedefinitionPassword} title="Redefine Password" />
        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);
export default PrivateRoutes;
