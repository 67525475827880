export const APP_TOKEN = '@q2-dashboard-receivers';

export const Months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const WeekDaysShort = [
  'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab',
];

export const WeekDaysLong = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

export const TaxesQ2LinkSorted = [
  { installment: 'Crédito 1x (D+15)', percentage: 3.90, lightBlue: false },
  { installment: 'Crédito 2x (D+15)', percentage: 5.90, lightBlue: true },
  { installment: 'Crédito 3x (D+15)', percentage: 6.90, lightBlue: false },
  { installment: 'Crédito 4x (D+15)', percentage: 7.90, lightBlue: true },
  { installment: 'Crédito 5x (D+15)', percentage: 8.70, lightBlue: false },
  { installment: 'Crédito 6x (D+15)', percentage: 9.60, lightBlue: true },
  { installment: 'Crédito 7x (D+15)', percentage: 9.90, lightBlue: false },
  { installment: 'Crédito 8x (D+15)', percentage: 10.50, lightBlue: true },
  { installment: 'Crédito 9x (D+15)', percentage: 11.40, lightBlue: false },
  { installment: 'Crédito 10x (D+15)', percentage: 12.30, lightBlue: true },
  { installment: 'Crédito 11x (D+15)', percentage: 12.60, lightBlue: false },
  { installment: 'Crédito 12x (D+15)', percentage: 12.90, lightBlue: true },
];

export const TaxesQ2Link = [
  { installment: 'Crédito 1x (D+15)', percentage: 3.90, lightBlue: false },
  { installment: 'Crédito 7x (D+15)', percentage: 9.90, lightBlue: true },
  { installment: 'Crédito 2x (D+15)', percentage: 5.90, lightBlue: true },
  { installment: 'Crédito 8x (D+15)', percentage: 10.50, lightBlue: false },
  { installment: 'Crédito 3x (D+15)', percentage: 6.90, lightBlue: false },
  { installment: 'Crédito 9x (D+15)', percentage: 11.40, lightBlue: true },
  { installment: 'Crédito 4x (D+15)', percentage: 7.90, lightBlue: true },
  { installment: 'Crédito 10x (D+15)', percentage: 12.30, lightBlue: false },
  { installment: 'Crédito 5x (D+15)', percentage: 8.70, lightBlue: false },
  { installment: 'Crédito 11x (D+15)', percentage: 12.60, lightBlue: true },
  { installment: 'Crédito 6x (D+15)', percentage: 9.60, lightBlue: true },
  { installment: 'Crédito 12x (D+15)', percentage: 12.90, lightBlue: false },
];

export const periodArray: string[] = [
  'Mês atual',
  'Últimos 30 dias',
  'Últimos 15 dias',
  'Últimos 7 dias',
  'Ontem',
  'Hoje',
];

export const franchisesAutoApproval = [20, 80, 82, 84];
