import React, { useEffect } from 'react';
import { Route as Component, RouteProps } from 'react-router-dom';
import { themeAPP } from '../constants';
import { isQ2Pay } from '../utils';

interface Props extends RouteProps {
  title?: string;
}

const Route: React.FC<Props> = (props) => {
  const { title } = props;

  const iconHeart = isQ2Pay(themeAPP) ? '💙' : '💚';
  const companyName = isQ2Pay(themeAPP) ? 'Quero2 Pay' : 'UnimedPay';

  useEffect(() => {
    document.title = title
      ? `${iconHeart} Minha conta | ${title} | ${companyName}`
      : `${iconHeart} Minha conta | ${companyName}`;
  }, [title]);

  return <Component {...props} />;
};

export default Route;
