import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';

import * as jwt from 'jsonwebtoken';
import { LoginData } from '../pages/Login/types';
import {
  getToken,
  removeUser,
  setUser,
  getUser,
} from '../services/user';
import { login } from '../services/user/requests';

interface AuthContextProps {
  authenticated: boolean;
  loading: boolean;
  login: (form: LoginData) => Promise<boolean>;
  logout: () => void;
}

interface Reference {
  type: string;
  hash: string;
}

interface LoginResponse {
  userId: string;
  references: Reference[];
  exp: number;
  name: string;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);

  const signIn = async (form: LoginData) => {
    setLoading(true);
    try {
      const data = await login(form);
      const loginToken = data.data.token;
      const decoded = jwt.decode(loginToken) as LoginResponse || null;

      setUser({
        token: loginToken,
        hash: decoded.references[0].hash,
        userId: decoded.userId,
        name: decoded.name,
      });
      setToken(loginToken);
    } catch (error) {
      return false;
    }
    setLoading(false);
    return true;
  };

  const signOut = () => {
    removeUser();
    setToken(null);
  };

  useEffect(() => {
    if (getToken()) { // Prevent old connections
      // signOut();
      setLoading(false);
      return;
    }
    setToken(getToken());
    setLoading(false);
    if (getToken() && getUser()) {
      const newUser = getUser();
      if (newUser && newUser.token) {
        delete newUser.token;
        setUser({
          ...newUser,
        });
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!token,
        loading,
        login: signIn,
        logout: signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  return context;
};
