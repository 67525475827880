import styled from 'styled-components';
import warning from '../../../../assets/icons/warning.svg';
import todo from '../../../../assets/icons/todo.svg';

export const Wrapper = styled.div``;

export const CardHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`;
export const TransactionsReportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const ToDoIcon = styled.img.attrs({
  src: todo,
  alt: 'Lista',
})`
  @media (max-width: 600px) {
    position: absolute;
    left: 10px;
  }

  @media (max-width: 390px) {
    display: none;
  }
`;

export const TransactionsReport = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 16px;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 600px) {
    position: relative;
    margin-left: 0;
  }
`;

export const WarningContainer = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
  @media (max-width: 670px) {
    max-width: 220px;
  }
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 50%;
`;

export const WarningText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 10px;
`;

export const Warning = styled.img.attrs({
  src: warning,
  alt: 'Alerta',
})``;

export const CardContent = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 40fr 40fr 40fr 20fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  @media (max-width: 735px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
`;

export const FullValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.secondaryLightBlue};
  padding: 10px;
  height: 75px;
`;

export const FullValueText = styled.h3`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  span {
    font-weight: bold;
  }
`;

export const FullValue = styled(FullValueText)`
  font-size: 27px;
  @media (max-width: 985px) {
    font-size: 24px;
  }

  @media (max-width: 775px) {
    font-size: 20px;
  }
`;

export const NetValueContainer = styled(FullValueContainer)``;

export const NetValueText = styled(FullValueText)``;

export const NetValue = styled(FullValue)``;

export const TransactionsAmountContainer = styled(NetValueContainer)`
  background-color: transparent;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const TransactionsAmountText = styled.h4`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TransactionsAmount = styled(TransactionsAmountText)`
  font-weight: bold;
  margin-left: 4px;
`;

export const ShowResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ShowResultsText = styled(TransactionsAmountText)`
  align-self: flex-end;
`;

export const ShowResults = styled(TransactionsAmount)`
  text-align: right;
  white-space: nowrap;
`;

export const ShowReaulstsSpan = styled.span`
  margin: 0 2px;
`;
