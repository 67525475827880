import styled from 'styled-components';

export default styled.button.attrs({
  type: 'submit',
})`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 8px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Roboto Condensed';
  border: none;

  &.danger{
    background-color: #E56877;
  }
  &.primary{
    background-color: #256DB1;
  }
  &.sucess{
    background-color: #56D59A;
  }
  :hover{
    cursor: pointer;
  }
`;
