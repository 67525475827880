import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const formatDate = (date: string | Date) => format(new Date(date), 'dd/MM/yyyy', {
  locale: ptBR,
});

const getFirstDayOfMonth = () => {
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  return firstDay;
};

const getLastDayOfMonth = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return lastDayOfMonth;
};

const formatDateShortString = (date: string | Date) => format(new Date(date), "dd 'de' MMM yyyy", {
  locale: ptBR,
});

const formatDateWithWeekDay = (date: string): string => {
  const [day, month, year] = date.split('-');
  return format(
    new Date(Number(year), Number(month) - 1, Number(day)), "EEE '-' dd/MM/yyyy",
    {
      locale: ptBR,
    },
  );
};

const getDayFromDaysAgo = (decrementDays: number) => {
  const date = new Date();
  date.setDate(date.getDate() - decrementDays);
  return date;
};

const dateMask = (value: string): string => {
  const newValue = value.replace(/\D/g, '').slice(0, 10);
  if (newValue.length >= 5) {
    return `${newValue.slice(0, 2)}/${newValue.slice(2, 4)}/${newValue.slice(4)}`;
  }
  if (newValue.length >= 3) {
    return `${newValue.slice(0, 2)}/${newValue.slice(2)}`;
  }
  return newValue;
};

export {
  formatDate,
  formatDateShortString,
  formatDateWithWeekDay,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getDayFromDaysAgo,
  dateMask,
};
