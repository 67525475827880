import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Split from '../pages/Split';
import Route from './Route';

const Login = lazy(() => import('../pages/Login'));
const ResetPassword = lazy(() => import('../pages/PasswordReset'));
const RedefinitionPassword = lazy(() => import('../pages/PasswordRedefinition'));
const NotFound = lazy(() => import('../pages/NotFound'));

const PublicRoutes: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path="/" component={Login} title="Login" />
        <Route exact path="/home" component={Split} title="Home" />
        <Route exact path="/reset-password" component={ResetPassword} title="Reset Password" />
        <Route exact path="/password-redefinition" component={RedefinitionPassword} title="Redefine Password" />
        <Route path="*" component={NotFound} title="Not Found" />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default PublicRoutes;
