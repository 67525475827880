import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthContext';
import {
  Container,
  CardMenuMobile,
  LogoContainer,
  Logo,
  MenuContainer,
  MenuItem,
  MenuItemMobile,
  MenuItemMobileDense,
  LogoutItemMobileDense,
  IconContainer,
  MenuItemMobileText,
  MenuMobile,
  MenuMobileIcon,
  DashboardPrimaryIcon,
  SplitIcon,
  CloseMenuIcon,
  SignOutPrimaryIcon,
  LogoutContainer,
  Logout,
  LogoutText,
  SignOutIcon,
  LogoutButton,
  SpinnerContainer,
  MyData,
  Warning,
} from './styles';
import SentPasswordModal from '../SentPasswordModal';

const Header: React.FC<{
  openedMenu: (value: boolean) => void; closeMenu: boolean }> = ({ openedMenu, closeMenu }) => {
  const { logout } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [openMyData, setOpenMyData] = useState<boolean>(false);
  const [showSentPasswordModal, setShowSentPasswordModal] = useState<boolean>(false);
  const [errorInfo] = useState<boolean>(false);

  const handleClickOut = useCallback((e: MouseEvent) => {
    const element = e.target as HTMLElement;
    if (element.id !== 'my-data-btn'
    && element.id !== 'my-data-ul'
    && element.id !== 'my-data-text'
    ) {
      setOpenMyData(false);
    }
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [closeMenu]);

  useEffect(() => {
    openedMenu(open);
  }, [open]);

  useEffect(() => {
    document.addEventListener('click', handleClickOut);
    return () => {
      document.removeEventListener('click', handleClickOut);
    };
  }, []);

  return (
    <>
      <SentPasswordModal
        setShow={setShowSentPasswordModal}
        show={showSentPasswordModal}
      />
      <Container>
        <LogoContainer to="/">
          <Logo alt="Logo Quero2Pay" />
        </LogoContainer>
        <MenuContainer>
          <Link to="/home">
            <MenuItem>
              <IconContainer>
                <SplitIcon />
              </IconContainer>
            </MenuItem>
          </Link>
        </MenuContainer>
        <MenuMobile onClick={() => setOpen(!open)}>
          <MenuMobileIcon />
        </MenuMobile>
        <LogoutContainer>
          {errorInfo && (
            <MyData id="my-data-ul" show={openMyData}>
              <SpinnerContainer>
                <Warning />
              </SpinnerContainer>
            </MyData>
          )}
          <Logout to="/" onClick={logout} title="Sair para Login">
            <LogoutText>Sair</LogoutText>
            <LogoutButton type="button">
              <SignOutIcon />
            </LogoutButton>
          </Logout>
        </LogoutContainer>
      </Container>
      <CardMenuMobile open={open}>
        <Link className="m1" to="/home">
          <MenuItemMobile>
            <DashboardPrimaryIcon />
            <MenuItemMobileText>Início</MenuItemMobileText>
          </MenuItemMobile>
        </Link>
        <LogoutItemMobileDense
          style={{
            gridRowStart: 'auto',
          }}
          to="/"
          className="m5"
          onClick={logout}
        >
          <MenuItemMobileText>Sair</MenuItemMobileText>
          <SignOutPrimaryIcon />
        </LogoutItemMobileDense>
        <MenuItemMobileDense
          style={{
            gridRowStart: 'auto',
          }}
          className="m6"
          onClick={() => setOpen(false)}
        >
          <MenuItemMobileText>Fechar Menu</MenuItemMobileText>
          <CloseMenuIcon />
        </MenuItemMobileDense>
      </CardMenuMobile>
    </>
  );
};

export default Header;
