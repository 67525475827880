import styled from 'styled-components';
import { Link } from 'react-router-dom';
import todo from '../../assets/icons/todo.svg';
import warning from '../../assets/icons/warning.svg';
import filter from '../../assets/icons/filter.svg';
import download from '../../assets/icons/download.svg';
import arrowSelect from '../../assets/icons/arrow-select.svg';
import previous from '../../assets/icons/previous-page.svg';
import next from '../../assets/icons/next-page.svg';
import closeIcon from '../../assets/icons/close-menu.svg';
import arrowDownWhite from '../../assets/icons/arrow-down-white-icon.svg';
import { TableProps, ReceiveradditionsContainnerProps } from './types';

export const Container = styled.div`
  .toolbar-top {
    @media screen and (max-width: 767px) {
      margin: 20px 22px 50px;
    }

    @media screen and (max-width: 600px) {
      margin: 20px 0 50px;
    }
  }

  .DayPicker {
    top: 37px;
    left: 0;
    font-size: 12px;
    bottom: unset;

    @media screen and (max-width: 767px) {
      top: 45px;
    }

    .DayPicker-NavButton.DayPicker-NavButton--prev {
      left: 5px;
    }

    .DayPicker-NavButton.DayPicker-NavButton--next {
      right: 5px;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
      background-color: ${({ theme }) => theme.colors.secondaryLightBlue} !important;
      color: ${({ theme }) => theme.colors.lightGray};
      border-radius: 0;
    }
  }
`;

export const ToDoIcon = styled.img.attrs({
  src: todo,
  alt: 'Lista',
})`
  @media (max-width: 600px) {
    position: absolute;
    left: 10px;
  }

  @media (max-width: 390px) {
    display: none;
  }
`;

export const CardHeader = styled.header`
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
`;

export const TransactionsReportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const TransactionsReport = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 16px;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 600px) {
    position: relative;
    margin-left: 0;
  }
`;

export const WarningContainer = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
  @media (max-width: 670px) {
    max-width: 220px;
  }
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 50%;
`;

export const WarningText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 10px;
`;

export const Warning = styled.img.attrs({
  src: warning,
  alt: 'Alerta',
})``;

export const CardContent = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 40fr 40fr 20fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  @media (max-width: 735px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

export const FullValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.secondaryLightBlue};
  padding: 10px;
  height: 75px;
`;

export const FullValueText = styled.h3`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  span {
    font-weight: bold;
  }
`;

export const FullValue = styled(FullValueText)`
  font-size: 27px;
  @media (max-width: 985px) {
    font-size: 24px;
  }

  @media (max-width: 775px) {
    font-size: 20px;
  }
`;

export const NetValueContainer = styled(FullValueContainer)``;

export const NetValueText = styled(FullValueText)``;

export const NetValue = styled(FullValue)``;

export const TransactionsAmountContainer = styled(NetValueContainer)`
  background-color: transparent;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

export const TransactionsAmountText = styled.h4`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const TransactionsAmount = styled(TransactionsAmountText)`
  font-weight: bold;
  margin-left: 4px;
`;

export const ShowResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const ShowResultsText = styled(TransactionsAmountText)`
  align-self: flex-end;
`;

export const ShowResults = styled(TransactionsAmount)`
  text-align: right;
  white-space: nowrap;
`;

export const ShowReaulstsSpan = styled.span`
  margin: 0 2px;
`;

export const ButtonsContainer = styled.section`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

export const FilterButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
`;

export const Filter = styled.img.attrs({
  src: filter,
  alt: 'Filtrar',
})`
  width: 100%;
`;

export const CloseFilterIcon = styled.img.attrs({
  src: closeIcon,
  alt: 'Fechar',
})`
  width: 100%;
`;

export const FilterText = styled.p`
  font-style: italic;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  margin-left: 4px;
`;

export const DownloadSheetButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
  display: flex;
  transition: all 0.4s ease-in-out;
  :active {
    transform: scale(0.98);
  }
  :hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
`;

export const DownloadSheetText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 8px;
`;

export const Download = styled.img.attrs({
  src: download,
  alt: 'Download',
})``;

export const TableContainer = styled.div`
padding-top: 30px;
`;

export const Table = styled.table<TableProps>`
  padding-top: 40px;
  z-index: 2;
  user-select: none;
  border-collapse: collapse;
  width: 100%;
  position: relative;
  top: -7px;
  ${(props) => (props.show ? '' : 'display: none;')}
  @media (max-width: 1080px) {
    th {
      border: none;
    }
  }
  @media (min-width: 767px) {
    top: -9px;
  }
`;

export const THead = styled.thead`
  border-radius: 20px;
`;

export const TBody = styled.tbody`
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
`;

export const Tr = styled.tr`
  position: relative;
  :nth-child(even) {
    background-color: ${({ theme }) => theme.colors.white};
  }
  :nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.secondaryLightBlue};
  }

  :last-child {
    td:first-child {
      border-bottom-left-radius: 20px;
    }
    td:last-child {
      border-bottom-right-radius: 20px;
    }
  }

  td:not(:last-child) {
    border-right: 1px solid #fff;
  }
`;

export const Th = styled.th`
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  height: 50px;
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 12px;
  padding: 16px 3vw;
  text-align: left;
  font-weight: bold;

  @media screen and (max-width: 1490px) {
    padding: 16px 2.5vw;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }

  @media screen and (max-width: 440px) {
    text-align: center;
  }

  :last-child {
    border-right: none;
    @media screen and (min-width: 601px) {
      border-radius: 0 20px 0 0;
    }
  }

  &:nth-child(1) {
    @media screen and (min-width: 601px) {
      border-radius: 20px 0 0 0;
    }
  }

  button {
    @media screen and (max-width: 600px) {
      width: 28px;
    }
  }
`;

export const Td = styled.td<{ reversed: boolean }>`
  font-weight: bold;
  font-size: 12px;
  padding: 16px 4px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => (props.reversed ? '#ADADAD' : props?.theme?.colors.primary)};
`;

export const TdDesk = styled(Td)`
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const PaginationContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
  z-index: 20;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const PerPageContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
`;

export const ResultPerPage = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.tertiaryGray};
  width: 65px;
  font-weight: 600;
`;

export const Select = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  appearance: none;
  border-radius: 14px;
  padding: 6px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  cursor: pointer;
  background: #fff;

  &:after {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background: url(${arrowSelect}) no-repeat center;
  }
`;

export const Option = styled.div``;

export const OptionText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 32px 0 0px;
`;

export const SelectDropDown = styled.div<{ itIsOpen: boolean }>`
  position: absolute;
  bottom: 42px;
  left: 0;
  width: 100%;
  max-height: ${(props) => (props.itIsOpen ? '500px' : '0')};
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 1;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

export const SelectDropDownUl = styled.ul`
  list-style: none;
`;

export const SelectDropDownLi = styled.li`
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryTransparent};
    color: ${({ theme }) => theme.colors.primary};

    p {
      font-weight: bold;
    }
  }
`;

export const SelectDropDownLiP = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

export const TotalPagesContainer = styled.div`
  display: flex;
  margin: 0 8px;
`;

export const ShowPage = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.tertiaryGray};
  font-weight: bold;
  margin-right: 4px;
`;

export const CurrentPage = styled(ShowPage)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const CurrentPageSpan = styled.span`
  margin: 0 6px;
`;

export const TabsContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 9vw;
  max-height: 52px;
  display: flex;
  margin-left: 9px;
  @media only screen and (min-width: 415px) and (max-width: 599px) {
    margin-left: 0;
  }
  @media (min-width: 875px) {
    padding-inline-start: 10px;
  }
  @media (max-width: 767px) {
    margin: 0 12px -3px;
  }
  @media (max-width: 600px) {
    margin: 0;
    max-width: 100%;
  }
`;

export const PreviousButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NextButton = styled(PreviousButton)``;

export const Previous = styled.img.attrs({
  src: previous,
  alt: 'Anterior',
})``;

export const Next = styled.img.attrs({
  src: next,
  alt: 'Próximo',
})``;

export const Page = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 16px;
  height: 40px;
  border-radius: 14px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

export const ReceiveradditionsContainner = styled.div<ReceiveradditionsContainnerProps>`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
`;

export const NewReceiver = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;

  width: 154px;
  height: 36px;

  background: #56D59A;
  border-radius: 12px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 8px */

  text-align: center;
  text-decoration: none;
  color: #FFFFFF;

`;

export const Separator = styled.div`
  width: 0px;
  height: 36px;

  border: 1px solid #CCCCCC;
  margin: 0px 12.5px;
`;

export const OrderButton = styled.button<{ turnUp: boolean }>`
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: ${(props) => (props.turnUp ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)')};
    display: block;
    appearance: none;
    border: none;
    background: url(${arrowDownWhite}) no-repeat center;
    background-size: 40%;
    width: 4vw;
    max-width: 38px;
    height: 100%;
    cursor: pointer;
    transition: transform .4s ease-in-out;

    @media screen and (max-width: 440px) {
      opacity: 0;
      right: 0;
      left: 0;
      width: 100% !important;
      max-width: 100%;
    }
`;
