import React, { useEffect } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { themeAPP, ThemeQ2 } from '../constants';
import { AuthProvider } from '../hooks/AuthContext';
import Routes from '../routes';
import { GlobalStyle } from '../styles';
import { themeQ2Pay, themeUnimedPay } from '../theme';

import { changeFavicon, isQ2Pay } from '../utils';

const App: React.FC = () => {
  useEffect(() => {
    if (themeAPP === ThemeQ2.unimed) {
      changeFavicon('./unimedFavicon.svg');
    }
  }, []);

  return (
    <ThemeProvider theme={isQ2Pay(themeAPP) ? themeQ2Pay : themeUnimedPay}>
      <GlobalStyle />
      <AuthProvider>
        <Routes />
        <ToastContainer
          position="bottom-center"
          newestOnTop
          transition={Slide}
          hideProgressBar
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
