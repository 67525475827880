import React, { FormEvent, useEffect, useState } from 'react';
import { Props } from './types';
import {
  Container, Modal, Title,
  Text, ActionContainer, Link, SaveInfoWrapper, SaveCheck, SaveText,
} from './styles';
import ActionButton from '../../../../components/ActionButton';
import { getAccepted, getUser, setUser } from '../../../../services/user';

const AceptModal: React.FC<Props> = () => {
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false);
  const [agreed, setAgreed] = useState<boolean>(false);
  const handleDisableScroll = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (showAcceptModal) {
      window.addEventListener('scroll', handleDisableScroll);
      return () => {
        window.removeEventListener('scroll', handleDisableScroll);
      };
    }
    return () => {};
  }, [showAcceptModal]);

  useEffect(() => {
    if (!getAccepted()) {
      setShowAcceptModal(true);
    }
  }, []);

  const handlerSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (agreed) {
      setUser({
        ...getUser(),
        accepted: true,
      });
      setShowAcceptModal(false);
    }
  };

  return (
    <Container
      show={showAcceptModal}
    >
      <Modal show={showAcceptModal} onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handlerSubmit}>
          <Title>Termos de uso e Políticas de privacidade</Title>
          <Text>
            Para utilizar os recursos que nós da
            {' '}
            <span>Quero 2 Pay</span>
            {' '}
            oferecemos
            , é necessário que você
            {' '}
            <Link href="https://www.quero2pay.com.br/termos-de-uso-q2link/" target="blank">
              aceite os termos de uso
            </Link>
            {' '}
            e também as
            {' '}
            <Link href="https://quero2pay.com.br/politicas-de-privacidade/" target="blank">
              políticas de privacidade.
            </Link>
          </Text>
          <ActionContainer>
            <SaveInfoWrapper>
              <SaveCheck checked={agreed} required onChange={() => (setAgreed(!agreed))} />
              <SaveText>
                Declaro que li e concordo com os
                {' '}
                <b>Termos de uso</b>
                {' '}
                e aceito todas as
                {' '}
                <b>políticas de privacidade</b>
              </SaveText>
            </SaveInfoWrapper>
            <ActionButton className="sucess">
              SALVAR
            </ActionButton>
          </ActionContainer>
        </form>
      </Modal>
    </Container>
  );
};

export default AceptModal;
