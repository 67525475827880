import { APP_TOKEN } from '../utils';

interface User {
  token?: string;
  hash?: string;
  userId?: string;
  accepted?: boolean;
  name?: string;
}

const setUser = (user: User) => {
  localStorage.setItem(APP_TOKEN, JSON.stringify({
    ...user,
  }));
};

const getUser = (): User => JSON.parse(localStorage.getItem(APP_TOKEN) || '');

const removeUser = () => localStorage.clear();

const getToken = () => {
  const { token } = JSON.parse(localStorage.getItem(APP_TOKEN) || '{}');
  return token;
};

const getAccepted = () => {
  const { accepted } = JSON.parse(localStorage.getItem(APP_TOKEN) || '{}');
  return accepted;
};

const getName = () => {
  const { name } = JSON.parse(localStorage.getItem(APP_TOKEN) || '{}');
  return name || '';
};

export {
  setUser,
  getUser,
  removeUser,
  getToken,
  getAccepted,
  getName,
};
