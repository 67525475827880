import api from '../api';
import q2payApi from '../q2payApi';
import { getUser } from '../user';
import {
  ResponseSplitTransactionsList,
  RegisterReceiver,
  GetSplitTotalsReportProps,
  GetSplitTransactionListProps,
} from './type';

export const getSplitTotalsReport = async ({
  startDate, endDate,
}:GetSplitTotalsReportProps) => {
  try {
    const user = getUser();
    const { data: response } = await q2payApi.get(
      `/transaction/v1/dashboard/receivers/${user.hash}/reports?startDate=${startDate}&endDate=${endDate}`,
    );
    return response;
  } catch (error: any) {
    throw error?.response;
  }
};

export const getSplitTransactionsListData = async ({
  startDate, endDate, limit, page,
}:GetSplitTransactionListProps): Promise<ResponseSplitTransactionsList> => {
  try {
    const user = getUser();
    const { data: response } = await q2payApi.get(
      `/transaction/v1/dashboard/receivers/${user.hash}/transactions?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${page}`,
    );
    return response;
  } catch (error: any) {
    throw error?.response;
  }
};

export const getBankCodeList = async () => {
  try {
    const { data: response } = await api.get('/banks');
    return response;
  } catch (error: any) {
    throw error?.response;
  }
};

export const postReceiverCreate = async (data : RegisterReceiver) => {
  try {
    const { data: response } = await api.post('receivers', data);
    return response;
  } catch (error: any) {
    throw error?.response;
  }
};
